<template>
  <div class="widget-intro-text-block" data-animate="preloader-opacity">
    <div
      class="widget-intro-text-block-content"
      :class="{ 'text-xl-1': !smallFontSize }"
      v-html="text"
    />
  </div>
</template>

<script>
export default {
  name: 'WidgetIntroTextBlock',
}
</script>

<script setup>
const props = defineProps({
  text: String,
  smallFontSize: Boolean,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
